export const useApi: typeof useFetch = (url, opts?) => {
  const config = useRuntimeConfig()

  return useFetch(url, {
    baseURL: config.public.baseURL,
    credentials: 'include',

    async onRequest({ options }) {
      const headers = options.headers ||= {}
      options.headers = await setupHeaders(headers)
    },

    onResponseError({ response }) {
      if ([401, 403, 404, 500].includes(response.status)) {
        showError({
          message: response._data.detail,
          statusCode: response.status,
        })
        return
      }
    },

    ...opts
  })
}
